var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("th", { attrs: { scope: "col" } }, [
    _c(
      "a",
      {
        staticClass: "group inline-flex cursor-pointer",
        on: {
          click: function ($event) {
            return _vm.progressSort()
          },
        },
      },
      [
        _vm._t("default"),
        _c(
          "span",
          {
            staticClass:
              "ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300",
          },
          [
            _vm.sort === "desc"
              ? _c(
                  "svg",
                  {
                    staticClass: "h-5 w-5",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 20 20",
                      fill: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        d: "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z",
                        "clip-rule": "evenodd",
                      },
                    }),
                  ]
                )
              : _vm.sort === "asc"
              ? _c(
                  "svg",
                  {
                    staticClass: "h-5 w-5",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 20 20",
                      fill: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        d: "M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z",
                        "clip-rule": "evenodd",
                      },
                    }),
                  ]
                )
              : _c("div", { staticClass: "h-5 w-5" }),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }